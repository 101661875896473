/* src/ComingSoonPage.css */

.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
  }
  
  .coming-soon-container h1 {
    font-size: 3rem;
    color: #333;
  }
  
  .coming-soon-container p {
    font-size: 1.5rem;
    color: #666;
    margin-top: 20px;
  }
  