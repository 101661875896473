/* styles.css */

.why-choose-us {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .why-choose-us h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .why-choose-us ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    justify-content: center;
  }
  
  .why-choose-us li {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    flex: 1 1 calc(50% - 40px); /* Two boxes per row, with some margin */
    max-width: calc(50% - 40px);
    box-sizing: border-box;
    text-align: left;
  }
  
  .why-choose-us li strong {
    font-weight: bold;
    color: #007bff;
    display: block;
    margin-bottom: 10px;
  }
  
  .why-choose-us li p {
    margin: 0;
    color: #555;
  }
  
  .icon {
    margin-right: 10px;
    color: #007bff;
    font-size: 24px;
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    .why-choose-us li {
      flex: 1 1 100%; /* Full width for mobile */
      max-width: 100%;
    }
  }
  