.a-all{
    padding-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(248, 249, 250);
}

.width{
    max-width: 1050px;
    text-align: justify;
   
}

.ww{
    display: flex;
    justify-content: center;
}

.a-image{
    padding-bottom: 50px;
    padding: 40px;
    padding-top: 20px;
    
}
.a-image img{
    
    width: 500px;
    border-radius: 20px;
    height: 380px;
   
    
    
    
}

.a-text{
   

}

.a-text h1{
    font-family: cursive;
    font-weight: 300px;
    font-size: 50px;
    color: rgb(48, 48, 148);
   
    
}


.heading{
    text-align: center;
    font-size: 40px;
    color: rgb(48, 48, 148);
}

.heading2{
    font-size: 25px;
    font-family: serif;
}
.a-text p{
    max-width: 700px;
    text-align: justify;
}


.h-button{
   background-color:  rgb(48, 48, 148);; 
}

.heading3{
    width: 1000px;
}



@media only screen and (max-width: 767px) {

.a-text{
    order: 1;
}

.a-image{
    order:2;
    
    padding-top: 17px;
}


.a-text p{
    text-align: justify;
    max-width: 300px;
    
}


.a-image img{
    width: 350px;
}
.a-all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

}