/* styles.css */

.ah {
    position: relative;
    width: 100%;
  }
  

.image2{
  background-image: url('../images/slider1.jpeg');
}


.image1{
  background-image: url('../images/slider2.jpeg');
}


  .intro {
    height: 100vh; /* Set the height to 100% of the viewport height */
    position: relative;
  }
  
  .image1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(0px);
    z-index: -1;
  }

  .image2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(0px);
    z-index: -1;
  }
  
  .image1::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    z-index: 1;
  }


  .image2::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
    padding-top: 120px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .intro h1 {
    font-size: 60px;
    font-weight: 900;
  }
  
  .hi {
    font-size: 70px;
  }
  
  .h-button {
    font-size: 20px;
    border-radius: 30px;
    border: none;
    width: 240px;
    padding: 13px;
    background-color: green;
    color: aliceblue;
    margin-left: 18px;
  }
  
  .hb-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  @media only screen and (max-width: 767px) {
    .intro h1 {
      font-size: 40px;
      margin-top: -160px;
    }
  
    .hi {
      font-size: 55px;
      font-weight: bolder;
    }
  
    .image1 {
      background-attachment: scroll;
    }

    .image2 {
      background-attachment: scroll;
    }
  
    .intro {
      margin-top: 40px;
    }
  
    .hb-all {
      margin-top: 20px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
    }
  
    .h-button {
      margin-top: 25px;
      width: 200px;
    }
  }
  