.staff-container {
    max-width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .staff-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .staff-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .staff-table th, .staff-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .staff-table th {
    background-color: #007BFF;
    color: white;
  }
  
  .staff-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .staff-table tr:hover {
    background-color: #ddd;
  }
  
  .staff-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .staff-table thead {
      display: none;
    }
  
    .staff-table, .staff-table tbody, .staff-table tr, .staff-table td {
      display: block;
      width: 100%;
    }
  
    .staff-table tr {
      margin-bottom: 15px;
    }
  
    .staff-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .staff-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  